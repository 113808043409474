<template>
  <div class="he-ui-identify-page h-100 d-flex justify-content-center alignt-content-center w-100">
    <template v-if="staffIsLogged && !loading">
      <div class="he-ui-preview-container">
        <preview />
      </div>
      <div class="d-flex flex-column gap-3">
        <Button
          template="blue"
          rounded
          uppercase
          :onClick="onLogin"
          :text="$t('main.identify_patient')"/>
        <Button
          template="white"
          rounded
          uppercase
          :onClick="onNewPatient"
          :text="$t('main.new_patient')"/>
      </div>
    </template>
    <IdentifyStaff
      v-else
      @staffLogged="(value) => staffIsLogged = value"
      @loading="(value) => loading = value"/>
  </div>
</template>

<script>
import { FINGERS_DATA, HHID, IS_NEW_PATIENT } from '@/constants/constants'
import { SCAN_FINGERPRINT_PAGE_ROUTE_NAME, SCAN_MULTIFINGER_PAGE_ROUTE_NAME } from '@/router/routes'
import { mapActions, mapMutations } from 'vuex'
import Button from '@/components/MainButton'
import IdentifyStaff from '@/components/IdentifyStaff'
import Preview from '@/components/Preview'

export default {
  name: 'IdentifyPage',
  components: {
    Button,
    IdentifyStaff,
    Preview
  },
  data () {
    return {
      loading: false,
      staffIsLogged: false
    }
  },
  mounted () {
    this.resetPatientRegisterData()
  },
  methods: {
    ...mapActions([
      'createPatient'
    ]),
    ...mapMutations([
      'resetHandSelected',
      'resetFingerSelected'
    ]),
    onLogin () {
      this.resetHandSelected()
      this.resetFingerSelected()
      this.$router.push({
        name: SCAN_FINGERPRINT_PAGE_ROUTE_NAME
      })
    },
    onNewPatient () {
      this.resetHandSelected()
      this.resetFingerSelected()
      localStorage.setItem(IS_NEW_PATIENT, true)
      this.$router.push({
        name: SCAN_MULTIFINGER_PAGE_ROUTE_NAME
      })
    },
    resetPatientRegisterData () {
      localStorage.removeItem(FINGERS_DATA)
      localStorage.removeItem(HHID)
      localStorage.removeItem(IS_NEW_PATIENT)
    }
  }
}
</script>

<style lang="scss" scoped>
.he-ui-preview-container {
  width: 350px;
  height: 350px;
  margin-bottom: 45px;
  @media (max-height: 716px) {
    width: 300px;
    height: 300px;
  }
}
.he-ui-identify-page {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
